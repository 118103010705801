import React from 'react'
import { Link } from 'gatsby'
import Layout from 'components/layout'
import 'styles/tailwind.css'

// connect to the backend
import Amplify from 'aws-amplify'
import config from '../aws-exports'
Amplify.configure(config)


const IndexPage = () => (
    <Layout>
        <p>
            <Link to="/app/signup">Sign Up</Link><br />
            <Link to="/app/login">Login</Link><br />
            <Link to="/app/home">Home</Link><br />
            <Link to="/app/profile">Profile</Link><br />
            <Link to="/app/artists">Artists</Link><br />
            <Link to="/app/test">Test Page</Link><br />
        </p>
    </Layout>
)

export default IndexPage

